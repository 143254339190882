// gatsby-browser.js
import React from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyle from './src/styles/globalStyles';
import theme from './src/styles/theme';
import './src/styles/extraStyles.css'; // Import extra styles

export const wrapRootElement = ({ element }) => (
  <StyledThemeProvider theme={theme}>
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyle />
      {element}
    </MuiThemeProvider>
  </StyledThemeProvider>
);