// src/styles/theme.ts

import { createTheme } from '@mui/material/styles';
import { lightColors, darkColors } from './colors';

const createCustomTheme = (mode: 'light' | 'dark') => {
  const colors = mode === 'light' ? lightColors : darkColors;

  return createTheme({
    palette: {
      mode,
      background: {
        default: colors.background,
        paper: colors.white,
      },
      primary: {
        main: colors.primary,
      },
      secondary: {
        main: colors.secondary,
      },
      text: {
        primary: colors.text,
      },
      linkText: {
        main: colors.linkText,
      },
      customSuccess: {
        main: colors.success,
      },
      customWarning: {
        main: colors.warning,
      },
      customDanger: {
        main: colors.danger,
      },
    },
    typography: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    breakpoints: {
      values: {
        XS: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  });
};

export default createCustomTheme;

// // src/styles/theme.ts
// import { createTheme } from '@mui/material/styles';
// import { colors } from './colors';

// const theme = createTheme({
//   palette: {
//     background: {
//       default: colors.background,
//       paper: colors.white,
//     },
//     primary: {
//       main: colors.primary,
//     },
//     secondary: {
//       main: colors.secondary,
//     },
//     text: {
//       primary: colors.text,
//     },
//     linkText: {
//       main: colors.linkText,
//     },
//     customSuccess: {
//       main: colors.success,
//     },
//     customWarning: {
//       main: colors.warning,
//     },
//     customDanger: {
//       main: colors.danger,
//     },
//   },
//   typography: {
//     fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
//   },
//   breakpoints: {
//     values: {
//       xs: 0,
//       sm: 600,
//       md: 960,
//       lg: 1280,
//       xl: 1920,
//     },
//   },
// });

// export default theme;
