// src/styles/globalStyles.ts

import { createGlobalStyle } from 'styled-components';
import { lightColors, darkColors } from './colors';

const GlobalStyle = createGlobalStyle<{ themeMode: 'light' | 'dark' }>`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: ${({ themeMode }) => themeMode === 'light' ? lightColors.background : darkColors.background};
    color: ${({ themeMode }) => themeMode === 'light' ? lightColors.text : darkColors.text};
  }

  #___gatsby {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: ${({ themeMode }) => themeMode === 'light' ? lightColors.background : darkColors.background};
  }

  #gatsby-focus-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  /* Ensure focusable elements within hidden sections are not focusable */
  [aria-hidden="true"] a,
  [aria-hidden="true"] button,
  [aria-hidden="true"] input,
  [aria-hidden="true"] select,
  [aria-hidden="true"] textarea {
    tabindex: -1;
  }

  header, .main-content, footer {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
    width: 100%;
  }

  @media (min-width: 960px) {
    header, .main-content, footer {
      padding: 0 2rem;
    }
  }

  .main-content {
    flex: 1;
    background-color: ${({ themeMode }) => themeMode === 'light' ? lightColors.background : darkColors.background};
    color: ${({ themeMode }) => themeMode === 'light' ? lightColors.text : darkColors.text};

    @media (max-width: 600px) {
      padding: 1rem;
    }
  }

  section.pages__Section,
  .pages__Section {
    padding: 0 !important;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 1rem;
    font-weight: 600;
    line-height: 1.2;
  }

  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.75rem;
  }

  h4 {
    font-size: 1.5rem;
  }

  h5 {
    font-size: 1.25rem;
  }

  h6 {
    font-size: 1rem;
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
    line-height: 1.6;
  }
  
  a {
    color: ${({ themeMode }) => themeMode === 'light' ? lightColors.linkText : darkColors.linkText}; // Ensure sufficient contrast
    text-decoration: underline;
    font-weight: 500; // Increase font weight for better visibility
    &:hover {
      text-decoration: underline;
    }
  }

  ul, ol {
    margin-top: 0;
    margin-bottom: 1rem;
    padding-left: 1.5rem;
  }

  img {
    max-width: 100%;
    height: auto.
  }

  button {
    font-family: 'Roboto', sans-serif;
  }

  footer {
    padding: 1rem 2rem;
    background-color: ${({ themeMode }) => themeMode === 'light' ? lightColors.background : darkColors.background};
    text-align: center;

    @media (max-width: 600px) {
      padding: 0.5rem 1rem;
    }
  }

  /* Form styling */
  .form-container {
    padding: 2rem;
    background-color: ${({ themeMode }) => themeMode === 'light' ? lightColors.background : darkColors.background};
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  form {
    display: flex;
    flex-direction: column;
  }

  .form-group {
    margin-bottom: 1.66rem;
    display: flex;
    flex-direction: column;
  }

  .form-label {
    font-size: 0.90rem;
  }

  .form-input,
  .form-textarea {
    padding: 0.5rem;
    border: 1px solid ${({ themeMode }) => themeMode === 'light' ? lightColors.border : darkColors.border}; /* Use border color token */
    border-radius: 4px;
    background-color: ${({ themeMode }) => themeMode === 'light' ? lightColors.white : darkColors.background}; /* Ensure dark mode background */
    color: ${({ themeMode }) => themeMode === 'light' ? lightColors.text : darkColors.text};
  }

  .form-textarea {
    resize: vertical;
  }

  .form-button {
    padding: 0.75rem;
    font-weight: 500;
    font-size: 1.25rem;
    color: #fff;
    background-color: ${({ themeMode }) => themeMode === 'light' ? lightColors.primary : darkColors.primary};
    border: none;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background-color: ${({ themeMode }) => themeMode === 'light' ? lightColors.primary : darkColors.primary};
    }
  }

  .form-success {
    color: ${({ themeMode }) => themeMode === 'light' ? lightColors.success : darkColors.success};
    font-weight: bold;
    text-align: center;
  }
`;

export default GlobalStyle;