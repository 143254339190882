// src/styles/colors.ts

export const lightColors = {
  background: '#FFFEFE', 
  primary: '#24425E',
  secondary: '#4A5240',
  text: '#202020',
  linkText: '#4169E1',
  // linkText: '#003366',
  white: '#FFFFFF',
  danger: '#B22222',
  success: '#228B22',
  warning: '#FFA500',
  border: '#ccc',
}
export const darkColors = {
  background: '#202020',
  primary: '#4169E1',
  secondary: '#4A5240',
  text: '#FFFEFE',
  linkText: '#4169E1',
  white: '#FFFEFE',
  danger: '#B22222',
  success: '#228B22',
  warning: '#FFA500',
  border: '#444',
}
