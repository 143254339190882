exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-portfolio-items-item-1-tsx": () => import("./../../../src/pages/portfolio-items/item-1.tsx" /* webpackChunkName: "component---src-pages-portfolio-items-item-1-tsx" */),
  "component---src-pages-portfolio-items-item-2-tsx": () => import("./../../../src/pages/portfolio-items/item-2.tsx" /* webpackChunkName: "component---src-pages-portfolio-items-item-2-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */),
  "component---src-pages-resume-tsx": () => import("./../../../src/pages/resume.tsx" /* webpackChunkName: "component---src-pages-resume-tsx" */),
  "component---src-templates-portfolio-item-template-tsx": () => import("./../../../src/templates/portfolio-item-template.tsx" /* webpackChunkName: "component---src-templates-portfolio-item-template-tsx" */)
}

